import './home.css'
import leanPhone from '../../assets/img/LeanPhone.svg';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import BeneficiosCard from '../../components/beneficiosCard/BeneficiosCard';
import cardIcon from '../../assets/img/cardIcon.svg'
import recaudacionesIcon from '../../assets/img/recaudacionesIcon.svg'
import estadoIcon from '../../assets/img/estadoIcon.svg'
import soporteIcon from '../../assets/img/soporteIcon.svg'
import dashboardLean from '../../assets/img/DashboardLean.png'
import hardware from '../../assets/img/hardware.png'
import maquina1 from '../../assets/img/maquina1.png'
import ecoIcon from '../../assets/img/ecoIcon.svg'
import moneyIcon from '../../assets/img/moneyIcon.svg'
import ServicioCard from '../../components/servicioCard/ServicioCard';
import cobertorIcon from '../../assets/img/cobertorIcon.svg'
import planchaIcon from '../../assets/img/planchaIcon.svg'

function Home() {

  const navigate = useNavigate();

  return (
  <>
    <Navbar/>
    <div className="Home">
      <div className="container home-container">
        <h1 className="home-title d-lg-none">
          El mejor sistema
          <br/>
          de pago para 
          <br/>
          lavanderías
        </h1>
        <h1 className="home-title d-none d-lg-block">
          El mejor sistema de pago
          <br/>
          para lavanderías
        </h1>
        <p className="home-subtitle d-none d-lg-block">
        Añade el pago digital a tus lavadoras y secadoras, vende detergentes y otros servicios.
        <br/>
        Con <b>LeanPay</b> facilitas y aumentas tus ventas.
        </p>
        <p className="home-subtitle d-lg-none">
          Añade el pago digital a tus lavadoras y secadoras, vende detergentes y otros servicios.
          <br/>
          Con <b>LeanPay</b> facilitas y aumentas tus ventas.
        </p>
        <div className="text-center">
          <button className="btn btn-home-blue-i" onClick={()=>navigate("form-solicitar")}>Solicita tu demo</button>
        </div>
        <div className="text-center mt-5">
          <img className="img-vending img-fluid" src={leanPhone} alt="lean movil app"/>
        </div>
        <h1 className="second-title" id="comoFunciona">Los beneficios de un sistema integral</h1>
        <div className="text-center mt-4">
        <p className="home-subtitle">
          Tener un sistema de pago es importante, pero tener un sistema integral
          <br/>
          que te permita mejorar la experiencia de tus clientes, aun más.
        </p>
        </div>
        <div className="text-center">
          <button className="btn btn-home-blue-i" onClick={()=>navigate("form-solicitar")}>Solicita tu demo</button>
        </div>
        <div className='row mt-5 gy-4 gx-3'>
          <BeneficiosCard 
            title={"Metodo de pago"}
            subtitle={"Digital"}
            img={cardIcon}
            content={"Tu cliente escanea el código QR de la lavadora o secadora, selecciona su método de pago, y se activa el equipo. Una vez terminado el ciclo, se le notificara."}
            col={true}
          />
          <BeneficiosCard 
            title={"Recaudaciones"}
            subtitle={"Pago digital y monedas"}
            img={recaudacionesIcon}
            content={"En nuestro dashboard podrás visualizar en linea las transacciones realizadas, ya sea mediante pago digital o pago mediante monedas. Todo en un solo lugar."}
            col={true}
          />
          <BeneficiosCard 
            title={"Estado de equipos"}
            subtitle={"En linea"}
            img={estadoIcon}
            content={"Podrás saber en todo momento si tus equipos se encuentran conectados, o si eventualmente se desconectaron y requieren de asistencia técnica. "}
            col={true}
          />
          <BeneficiosCard 
            title={"Soporte"}
            subtitle={"24/7"}
            img={soporteIcon}
            content={"Te entregamos un soporte de clase mundial, en la instalación de tus dispositivos, pero más importante a tu cliente final, porque la experiencia de tus clientes es lo mas importante."}
            col={true}
          />
        </div>
        <h1 className="second-title" id='productos'>
          Tu control de mando, a un clic
        </h1>
        <p className="home-subtitle mt-4">
          Toda la información que necesitar ver, en un solo lugar.
          <br/>
          Visualiza todos tus KPI en linea, y mantén tus finanzas ordenadas.
        </p>
        <div className="text-center">
          <button className="btn btn-home-blue-i" onClick={()=>navigate("form-solicitar")}>Solicitalo aqui</button>
        </div>
        <div className="dashboard-img-container text-center mt-5 d-none d-lg-block mx-auto">
          <img alt="dashboard" className="img-fluid" src={dashboardLean} />
        </div>
        <div className="dashboard-img-container text-center mt-5 d-lg-none">
          <img alt="dashboard" className="" src={dashboardLean} height={500} />
        </div>
        <h1 className="second-title d-none d-lg-block" id='beneficiosEmpresa'>
          Más que un método de pago,
            <br/> 
          un ecosistema para lavanderías
        </h1>
        <h1 className="second-title d-lg-none" id='beneficiosEmpresa'>
          Más que un 
          <br/>
          método de pago,
          <br/>
          un ecosistema
          <br/>
          para lavanderías
        </h1>
        <p className="home-subtitle mt-4">
          Maximiza tus ganancias, ofrece la venta detergente ecológico y servicios de 
            <br/>
          lavado de plumones, planchado de prendas y mucho más.
        </p>
        <div className="text-center">
          <button className="btn btn-home-blue-i" onClick={()=>navigate("form-solicitar")}>Solicitalo aqui</button>
        </div>
        <div className="container-images">
          <div className="img1-container mt-5">
            <img src={hardware} alt="hardware lean" className={"img1 img-fluid"} />
            <div className="card-img-container">
              <BeneficiosCard 
                title={"Hardware IOT "}
                subtitle={"V 1.0 I LeanUP"}
                img={estadoIcon}
                content={"El mejor dispositivo desarrollado hasta el momento para soluciones de lavanderías comerciales. Seguridad, estabilidad y confiabilidad."}
                col={false}
              />
            </div>
          </div>
          <div className="img2-container" id="machine-detergente">
            <img src={maquina1} alt="hardware lean" className={"img2 img-fluid"} />
              <div className="card-img-container">
                <BeneficiosCard 
                  title={"Vending Machine"}
                  subtitle={"Detergente ecológico "}
                  img={ecoIcon}
                  content={"Podrás maximizar tus ganancias, vendiendo un producto innovador que además se hace cargo de un gran problema del planeta."}
                  col={false}
                />
            </div>
          </div>
          <div className="img3-container">
            <div className='d-flex align-items-end justify-content-start'>
              <img src={leanPhone} alt="hardware lean" className={"img3 img-fluid"} />
              <div className="servicios-container">
                <ServicioCard 
                  title={"Lava tu cobertor"}
                  subtitle={"Solicítalo de manera fácil."}
                  img={cobertorIcon}
                />
                <ServicioCard 
                  title={"Servicio de planchado"}
                  subtitle={"Planchado de prenda de vestir"}
                  img={planchaIcon}
                />
              </div>
            </div>
            <div className="card-img-container">
              <BeneficiosCard 
                title={"Servicios adicionales"}
                subtitle={"Lavado de plumones y panchado"}
                img={moneyIcon}
                content={"Tus clientes no solo necesitan lavar su ropa, también tienen otras necesidades que no tienen resueltas, ahora las resuelven a un clic."}
                col={false}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  </>
  );
}

export default Home;