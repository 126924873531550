import './navbar.css'
import LeanPayLogo from '../../assets/img/LeanPayLogo.svg'
import toggler from '../../assets/img/toggler.svg'
import { useNavigate, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Navbar() {

    const navigate = useNavigate();

    return (
        <div className="Navbar">
            <nav className="navbar navbar-expand-lg">
                <div className="container d-flex justify-content-around mx-lg-auto p-0">
                    <button className="navbar-toggler navbar-toggler-color" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <img alt="abrir menu" src={toggler} className="img-fluid"/>
                    </button>
                    <Link className="navbar-brand font-logo p-0 m-0 text-center brand-container" to="/">
                        <img src={LeanPayLogo} alt="Logo" className="img-navbar img-fluid"/>
                    </Link>
                    <div className="navbar-text d-lg-none">
                        <button type="button" className="btn btn-blue py-1" onClick={()=>navigate("form-solicitar")}>Solicitar</button>
                    </div>
                    <div className="collapse navbar-collapse d-md-flex justify-content-evenly" id="navbarSupportedContent">
                        <ul className="navbar-nav mb-2 my-lg-0 text-center">
                            <li className="nav-item">
                                <HashLink className="nav-link navbar-element" to="/#comoFunciona">Cómo funciona</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink className="nav-link navbar-element" to="/#productos">Productos</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink className="nav-link navbar-element" to="/#beneficiosEmpresa">Beneficios</HashLink>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link navbar-element" to="/quienes-somos">Quienes somos</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link navbar-element" to="/form-contacto">Contacto</Link>
                            </li>
                            <li className="nav-item d-lg-none">
                                <a className="nav-link navbar-element-blue" href="https://dashboard-laundry.leanupco.com/login">Iniciar sesión</a>
                            </li>
                        </ul>
                    </div>
                    <div className="rb-container d-none d-lg-flex align-content-center justify-content-between">
                        <a className='my-auto nav-link navbar-element-blue' href="https://dashboard-laundry.leanupco.com/login">Iniciar sesión</a>
                        <button type="button" className="btn btn-blue btn-solicitar-nav" onClick={()=>navigate("form-solicitar")}>Solicitar</button>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;