import { Routes, Route } from "react-router-dom"
import Home from "../views/home/Home";
import InformacionForm from "../views/Forms/informacionForm";
import ContactoForm from "../views/Forms/ContactoForm";
import AboutUs from "../views/aboutUs/AboutUs";
import ReportarErrorForm from "../views/Forms/ReportarErrorForm";

function AppRoutes() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="form-solicitar" element={ <InformacionForm/> } />
        <Route path="form-contacto" element={ <ContactoForm/> } />
        <Route path="quienes-somos" element={ <AboutUs/> } />
        <Route path="form-reportar" element={ <ReportarErrorForm/> } />
      </Routes>
    </div>
  );
}

export default AppRoutes;