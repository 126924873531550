import './form.css'
import HubspotContactForm from '../../components/HubspotContactForm/HubspotContactForm';
import { Link } from 'react-router-dom';
import flechaAzul from '../../assets/img/flechaAzul.svg'
import LeanPayLogo from '../../assets/img/LeanPayLogo.svg'

function ReportarErrorForm() {
  return (
    <main>
      <div className='form-header mt-5 d-md-none'>
        <Link className="d-flex align-items-center justify-content-center logo-container" to="/">
          <img alt="Logo" src={LeanPayLogo} />
        </Link>
        <p className="svc-text-font mt-4">
            Indícanos a continuación tu <b>información de contacto</b>
            <br/>
            y una <b>descripción del error</b>
        </p>
      </div>
      <div className="form-container">
      <div className='form-header-desktop text-center'>
        <Link className="logo-container" to="/">
            <img alt="Logo" src={LeanPayLogo}/>
        </Link>
        <p className="svc-text-font mt-5 mb-5">
            Indícanos a continuación tu <b>información de contacto</b>
            <br/>
            y una <b>descripción del error</b>
        </p>
      </div>
        <div className="container">
          <HubspotContactForm 
            region="na1"
            portalId="21901438"
            formId='5849ffcd-4e87-4d9b-a507-669d13aa182c'
          />
        </div> 
      <div className="form-footer mt-2">
            Al enviar esta solicitud, estás aceptando los
            <br/> 
            <span className='tc-font'>términos y condiciones</span> de LeanUPco
      </div>
      <div className='text-center mt-5'>
        <Link className='link-element-orange' to="/"><img alt="flecha-volver" src={flechaAzul} className="me-2" />Volver al inicio</Link>
      </div>
      </div>
    </main> 
  );
}

export default ReportarErrorForm;