import './aboutUs.css'
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

function AboutUs() {

  return (
  <>
    <Navbar/>
    <div className="container">
      <h1 className="about-title mx-3">Quiénes somos</h1>
      <div className="row mx-3">
        <div className="col-md-6 col-sm-12 content-container px-0">
          <p>
            Somos un equipo inquieto de jóvenes que desarrollamos soluciones que impactan de 
            manera positiva en el día a día de miles de usuarios, buscando siempre la excelencia 
            en la construcción de productos y servicios para el mundo de las lavanderías auto 
            atendidas de edificios.
          </p>
          <p>
            Nos hemos tomado el tiempo necesario para desarrollar un dispositivo que permita 
            conectar un sin fin de modelos de maquinas, para que no existan limitantes para 
            empezar a automatizar el pago de servicios de lavados, secados e inclusive de 
            maquinas vending de detergente.
          </p>
          <p>
            Trabajamos con las tecnologías más recientes para garantizar una experiencia de pago 
            segura, rápida y confiable. Estamos comprometidos en brindar una experiencia de 
            usuario excepcional, y en ayudar a nuestros clientes a maximizar sus ganancias y 
            simplificar sus procesos de negocio.
          </p>
        </div>
        <div className="col-md-6 col-sm-12 img-container px-0">
          {/* <h1>Imagen</h1> */}
        </div>
      </div>
    </div>
    <Footer/>
  </>
  );
}

export default AboutUs;