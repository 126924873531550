import './servicioCard.css'

function ServicioCard({ title, subtitle, img }) {
    return (
        <div className="ServicioCard mb-3">
            <div className='servicio-card-container container p-4'>
                <div className="d-flex justify-content-between">
                    <div className="vstack">
                        <p className={`${title==="Servicio de planchado"? "servicio-title2" : "servicio-title"}`}>{title}</p>
                        <p className={`${title==="Servicio de planchado"? "servicio-subtitle2 m-0" : "servicio-subtitle m-0"}`}>{subtitle}</p>
                    </div>
                    <img src={img} alt="imagen servicio" className='img-fluid'/>
                </div>
            </div>
        </div>
    );
}

export default ServicioCard;